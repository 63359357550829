import { reactive, readonly } from 'vue'
import { EnumsDomStorage, MetadataKey } from '@/types/enums'
import accountStore from '@/stores/account.store'
import unitStore from '@/stores/unit.store'
import documentsStore from '@/stores/documents.store'
import ApiError from '@/types/ApiError'
import b2buserStore from '@/units/b2b/stores/b2buser.store'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash.debounce'
import { languageOptions } from '@/assets/data/languages'

interface appStoreStates {
  languageCode: string
  initialLoading: boolean

  apiError: {
    code: number
    message: string
  } | null

  hideNavbar: boolean // Hide/Show Navbar for small screens
  disableNavbar: boolean // Disable Navbar complete (eg for impress)
  showBackToTop: boolean // Show BackToTop Button

  showUserFeedback: boolean

  disabledRoutes: Array<string> // List of names of routes who disabled (eg in menus)

  mainClasses: string
  screenSize: string
  screenWidth: number

  saveRequestRunning: boolean
  saveRequestError: ApiError | null

  changesOnPage: boolean

  productFruitLoaded: boolean
  mouseflowLoaded: boolean
  abTastyLoaded: boolean
  calendlyConsentGiven: boolean

  ignoreSm: boolean

  isB2b: boolean
  baseUrl: string

  lastRouteName: string

  isTopScrolled: boolean // is the site scrolled to top?
  headContact: {
    overflow: boolean
    width: number
    mainWidth: number
    showData: boolean | undefined
  }

  quickTippMounted: boolean
}

// Create general store for the application with initial data from localstorage (if available)
const state = reactive({
  languageCode: localStorage.getItem(EnumsDomStorage.LANGUAGE) || 'de', // TODO: || navigator.language.substring(0,2)
  initialLoading: true,

  hideNavbar: true, // default is true, to hide menu on <xl devices
  disableNavbar: false,
  showBackToTop: false,

  showUserFeedback: false,

  screenSize: '',
  screenWidth: 0,

  productFruitLoaded: false,
  mouseflowLoaded: false,

  ignoreSm: !!localStorage.getItem(EnumsDomStorage.IGNORE_SM),

  isB2b:
    window.location.href.includes(
      import.meta.env.VITE_B2B_BASE_URL as string,
    ) ||
    window.location.href.includes(
      import.meta.env.VITE_B2B_BASE_URL_FALLBACK as string,
    ),
  baseUrl: '',

  isTopScrolled: true,
  headContact: {},
} as appStoreStates)

if (state.isB2b) {
  state.baseUrl = window.location.href.includes(
    import.meta.env.VITE_B2B_BASE_URL as string,
  )
    ? (import.meta.env.VITE_B2B_BASE_URL as string)
    : (import.meta.env.VITE_B2B_BASE_URL_FALLBACK as string)
} else {
  state.baseUrl = window.location.href.includes(
    import.meta.env.VITE_B2C_BASE_URL as string,
  )
    ? (import.meta.env.VITE_B2C_BASE_URL as string)
    : (import.meta.env.VITE_B2C_BASE_URL_FALLBACK as string)
}

const setLanguage = (languageCode: string) => {
  state.languageCode = languageCode
  localStorage.setItem(EnumsDomStorage.LANGUAGE, languageCode)
}

const fetchPinnedLanguages = (): string[] =>
  languageOptions
    .filter((lang) => ['en', 'es', 'fr'].includes(lang.code))
    .map((lang) => lang.language)

const fetchOtherLanguages = (): string[] =>
  languageOptions
    .filter((lang) => !['en', 'es', 'fr'].includes(lang.code))
    .map((lang) => lang.language)

const fetchSortedLanguages = (): { options: string[] }[] => [
  {
    options: fetchPinnedLanguages(),
  },
  {
    options: fetchOtherLanguages(),
  },
]

const redirectToLogin = async () => {
  if (state.isB2b) {
    const queryString = new URLSearchParams({
      response_type: 'token',
      client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      redirect_uri: state.baseUrl,
      nonce: uuidv4(),
    }).toString()
    window.location.replace(
      `${import.meta.env.VITE_AUTH0_DOMAIN}/authorize?${queryString}`,
    )
  } else {
    const queryString = new URLSearchParams({
      response_type: 'id_token token',
      client_id: import.meta.env.VITE_AUTH0_B2C_CLIENT_ID,
      audience: import.meta.env.VITE_AUTH0_B2C_AUDIENCE,
      redirect_uri: state.baseUrl,
      nonce: uuidv4(),
      scope: 'openid email',
    }).toString()
    window.location.replace(
      `${import.meta.env.VITE_AUTH0_B2C_DOMAIN}/authorize?${queryString}`,
    )
  }
  // return a (never fulfilling) promise to gain some time for redirect. AD-8242
  await new Promise((resolve) => window.setTimeout(() => resolve(null), 5000))
}

const setProductFruitLoaded = (value: boolean) => {
  state.productFruitLoaded = value
}

const setMouseflowLoaded = (value: boolean) => {
  state.mouseflowLoaded = value
}
const setAbTastyLoaded = (value: boolean) => {
  state.abTastyLoaded = value
}
const setCalendlyConsentGiven = (value: boolean) => {
  state.calendlyConsentGiven = value
}

// TODO: What is Sm? Rename the method to something more descriptive
const setIgnoreSm = (value: boolean) => {
  state.ignoreSm = value
}

const setMainClasses = (classes: string) => {
  state.mainClasses = classes
}

const hideNavbar = (hide: boolean) => {
  state.hideNavbar = hide
}

const setDisableNavbar = (disable: boolean) => {
  state.disableNavbar = disable
}

const toggleInitialLoading = () => {
  state.initialLoading = !state.initialLoading
}

const setUserFeedback = (value: boolean) => {
  state.showUserFeedback = value
}

const addDisabledRoute = (routeName: string) => {
  if (!state.disabledRoutes) {
    state.disabledRoutes = []
  }
  state.disabledRoutes.push(routeName)
}

const setSaveRequest = (running: boolean) => {
  state.saveRequestRunning = running
}

const setSaveError = (error: ApiError | null) => {
  state.saveRequestError = error
}

const setShowBackToTop = (show: boolean) => {
  state.showBackToTop = show
}

const setHeadContact = (width: number | null, mainWidth: number | null) => {
  state.headContact.width = width ?? state.headContact.width
  state.headContact.mainWidth = mainWidth ?? state.headContact.mainWidth
  state.headContact.overflow =
    state.headContact.width >= state.headContact.mainWidth
}

const setShowContactData = (val: boolean, forced?: boolean) => {
  state.headContact.showData = val
}

// @ts-ignore
let unitCheckIntervall
let unitCheckIntervallCount = 0
export const loadInitialData = async () => {
  console.log('loadInitialData')
  try {
    // distinguish between b2c and b2b stores -> Fallback to b2c
    if (state.isB2b) {
      await b2buserStore.fetchUserData()
      if (localStorage.getItem(EnumsDomStorage.B2B_ACTIVE_COMPANY_ID)) {
        await b2buserStore.fetchCompanyData()
        await documentsStore.fetchDocuments()
      }

      toggleInitialLoading()
      window.dispatchEvent(new Event('initialLoadingCompleted'))
    } else {
      await accountStore.fetchAccountData()
      if (accountStore.state.account.units?.length <= 0) {
        console.log('[Unit-Check] No Unit found for this account')
        // no unit available -> try again 5 Times
        if (accountStore.getMetadataValue(MetadataKey.ONBOARDING_DONE)) {
          unitCheckIntervall = setInterval(async () => {
            console.log(
              '[Unit-Check] Intervall count: ',
              unitCheckIntervallCount,
            )
            await accountStore.fetchAccountData()
            if (accountStore.state.account.units?.length > 0) {
              console.log('[Unit-Check] Intervall: Still no unit ')
              // @ts-ignore
              clearInterval(unitCheckIntervall)
              loadInitialData()
            }
            unitCheckIntervallCount++

            if (unitCheckIntervallCount >= 5) {
              console.log(
                '[Unit-Check] Intervall: tried 5 times...still no unit -> error ',
              )

              accountStore.setUnitAvailable(false)
              // @ts-ignore
              clearInterval(unitCheckIntervall)
              // @ts-ignore
              await unitStore.setUnitData(null)
              toggleInitialLoading()
            }
          }, 10000)
        } else {
          toggleInitialLoading()
          window.dispatchEvent(new Event('initialLoadingCompleted'))
        }
      } else {
        console.log('[Unit-Check] Unit found!')
        await unitStore.fetchUnitData()
        await documentsStore.fetchDocuments()
        if (!unitStore.state.activeApplicationId) {
          unitStore.setActiveApplicationId()
        }

        toggleInitialLoading()
        window.dispatchEvent(new Event('initialLoadingCompleted'))
      }
    }
  } catch (error: any) {
    console.log('error', error)
    throw typeof error === 'string' ? error : error[0]
  }
}

const setApiError = (code: number, message: string) => {
  if (!code) {
    state.apiError = null
    return
  }
  state.apiError = {
    code: code,
    message: message,
  }
}

const setLastRouteName = (newLastRoute: string) => {
  state.lastRouteName = newLastRoute
}

const setChangesOnPage = (changes: boolean) => {
  state.changesOnPage = changes
}

const setIsTopScrolled = (isTop: boolean) => {
  if (state.isTopScrolled !== isTop) {
    state.headContact.showData = false
  }
  state.isTopScrolled = isTop
}

// resize/widthCalc functions for the app
window.addEventListener(
  'resize',
  debounce(() => _calcScreenSize(), 500),
)

const _calcScreenSize = () => {
  const width = window.innerWidth
  if (state.screenWidth === width) {
    return
  }
  state.screenWidth = width

  if (width < 640) {
    state.screenSize = 'xs'
    return
  }
  if (width < 768) {
    state.screenSize = 'sm'
    return
  }
  if (width < 1024) {
    state.screenSize = 'md'
    return
  }
  if (width < 1280) {
    state.screenSize = 'lg'
    return
  }
  if (width < 1536) {
    state.screenSize = 'xl'
    return
  }
  state.screenSize = '2xl'
}
_calcScreenSize()

const setQuickTippMounted = (newVal: boolean) => {
  state.quickTippMounted = newVal
}

// Export complete store at once
export default {
  state: readonly(state),
  setLanguage,
  fetchPinnedLanguages,
  fetchOtherLanguages,
  fetchSortedLanguages,
  toggleInitialLoading,
  loadInitialData,
  hideNavbar,
  setDisableNavbar,
  redirectToLogin,
  setUserFeedback,
  setApiError,
  addDisabledRoute,
  setMainClasses,
  setIgnoreSm,
  setSaveRequest,
  setSaveError,
  setProductFruitLoaded,
  setMouseflowLoaded,
  setLastRouteName,
  setChangesOnPage,
  setShowBackToTop,
  setHeadContact,
  setIsTopScrolled,
  setShowContactData,
  setAbTastyLoaded,
  setCalendlyConsentGiven,
  setQuickTippMounted,
}
